var navbar_initialized, transparent = !0,
    transparentDemo = !0,
    fixedTop = !1,
    backgroundOrange = !1,
    sidebar_mini_active = !1,
    toggle_initialized = !1,
    seq = 0,
    delays = 80,
    durations = 500,
    seq2 = 0,
    delays2 = 80,
    durations2 = 500;
(function() {
    isWindows = !!(-1 < navigator.platform.indexOf('Win')), isWindows && !$('body').hasClass('sidebar-mini') ? ($('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar(), $('html').addClass('perfect-scrollbar-on')) : $('html').addClass('perfect-scrollbar-off')
})(), $(document).ready(function() {
    $('[data-toggle="tooltip"], [rel="tooltip"]').tooltip(), $('[data-toggle="popover"]').each(function() {
        color_class = $(this).data('color'), $(this).popover({
            template: '<div class="popover popover-' + color_class + '" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
        })
    });
    var a = $('.tagsinput').data('color');
    0 != $('.tagsinput').length && $('.tagsinput').tagsinput(), $('.bootstrap-tagsinput').addClass('' + a + '-badge'), 0 != $('.selectpicker').length && $('.selectpicker').selectpicker({
        iconBase: 'now-ui-icons',
        tickIcon: 'ui-1_check'
    }), 0 == $('.full-screen-map').length && 0 == $('.bd-docs').length && $('.collapse').on('show.bs.collapse', function() {
        $(this).closest('.navbar').removeClass('navbar-transparent').addClass('bg-white')
    }).on('hide.bs.collapse', function() {
        $(this).closest('.navbar').addClass('navbar-transparent').removeClass('bg-white')
    }), nowuiDashboard.checkSidebarImage(), nowuiDashboard.initMinimizeSidebar(), $navbar = $('.navbar[color-on-scroll]'), scroll_distance = $navbar.attr('color-on-scroll') || 500, 0 != $('.navbar[color-on-scroll]').length && (nowuiDashboard.checkScrollForTransparentNavbar(), $(window).on('scroll', nowuiDashboard.checkScrollForTransparentNavbar)), $('.form-control').on('focus', function() {
        $(this).parent('.input-group').addClass('input-group-focus')
    }).on('blur', function() {
        $(this).parent('.input-group').removeClass('input-group-focus')
    }), $('.bootstrap-switch').each(function() {
        $this = $(this), data_on_label = $this.data('on-label') || '', data_off_label = $this.data('off-label') || '', $this.bootstrapSwitch({
            onText: data_on_label,
            offText: data_off_label
        })
    }), 992 <= $(window).width() && (big_image = $('.page-header-image[data-parallax="true"]'), $(window).on('scroll', nowuiDashboardDemo.checkScrollForParallax)), 0 != $('.sidebar-mini').length && (sidebar_mini_active = !0)
}), $(document).on('click', '.navbar-toggle', function() {
    $toggle = $(this), 1 == nowuiDashboard.misc.navbar_menu_visible ? ($('html').removeClass('nav-open'), nowuiDashboard.misc.navbar_menu_visible = 0, setTimeout(function() {
        $toggle.removeClass('toggled'), $('#bodyClick').remove()
    }, 550)) : (setTimeout(function() {
        $toggle.addClass('toggled')
    }, 580), div = '<div id="bodyClick"></div>', $(div).appendTo('body').click(function() {
        $('html').removeClass('nav-open'), nowuiDashboard.misc.navbar_menu_visible = 0, setTimeout(function() {
            $toggle.removeClass('toggled'), $('#bodyClick').remove()
        }, 550)
    }), $('html').addClass('nav-open'), nowuiDashboard.misc.navbar_menu_visible = 1)
}), $(window).resize(function() {
    seq = seq2 = 0, 0 == $('.full-screen-map').length && 0 == $('.bd-docs').length && ($navbar = $('.navbar'), isExpanded = $('.navbar').find('[data-toggle="collapse"]').attr('aria-expanded'), $navbar.hasClass('bg-white') && 991 < $(window).width() ? $navbar.removeClass('bg-white').addClass('navbar-transparent') : $navbar.hasClass('navbar-transparent') && 991 > $(window).width() && 'false' != isExpanded && $navbar.addClass('bg-white').removeClass('navbar-transparent'))
}), nowuiDashboard = {
    misc: {
        navbar_menu_visible: 0
    },
    checkScrollForTransparentNavbar: debounce(function() {
        $(document).scrollTop() > scroll_distance ? transparent && (transparent = !1, $('.navbar[color-on-scroll]').removeClass('navbar-transparent')) : !transparent && (transparent = !0, $('.navbar[color-on-scroll]').addClass('navbar-transparent'))
    }, 17),
    checkSidebarImage: function() {
        $sidebar = $('.sidebar'), image_src = $sidebar.data('image'), image_src !== void 0 && (sidebar_container = '<div class="sidebar-background" style="background-image: url(' + image_src + ') "/>', $sidebar.append(sidebar_container))
    },
    initMinimizeSidebar: function() {
        $('#minimizeSidebar').click(function() {
            $(this);
            !0 == sidebar_mini_active ? ($('body').removeClass('sidebar-mini'), sidebar_mini_active = !1, nowuiDashboard.showSidebarMessage('Sidebar mini deactivated...')) : ($('body').addClass('sidebar-mini'), sidebar_mini_active = !0, nowuiDashboard.showSidebarMessage('Sidebar mini activated...'));
            var a = setInterval(function() {
                window.dispatchEvent(new Event('resize'))
            }, 180);
            setTimeout(function() {
                clearInterval(a)
            }, 1e3)
        })
    },
    startAnimationForLineChart: function(a) {
        a.on('draw', function(a) {
            'line' === a.type || 'area' === a.type ? a.element.animate({
                d: {
                    begin: 600,
                    dur: 700,
                    from: a.path.clone().scale(1, 0).translate(0, a.chartRect.height()).stringify(),
                    to: a.path.clone().stringify(),
                    easing: Chartist.Svg.Easing.easeOutQuint
                }
            }) : 'point' === a.type && (seq++, a.element.animate({
                opacity: {
                    begin: seq * delays,
                    dur: durations,
                    from: 0,
                    to: 1,
                    easing: 'ease'
                }
            }))
        }), seq = 0
    },
    startAnimationForBarChart: function(a) {
        a.on('draw', function(a) {
            'bar' === a.type && (seq2++, a.element.animate({
                opacity: {
                    begin: seq2 * delays2,
                    dur: durations2,
                    from: 0,
                    to: 1,
                    easing: 'ease'
                }
            }))
        }), seq2 = 0
    },
    showSidebarMessage: function(a) {
        try {
            $.notify({
                icon: 'now-ui-icons ui-1_bell-53',
                message: a
            }, {
                type: 'info',
                timer: 4e3,
                placement: {
                    from: 'top',
                    align: 'right'
                }
            })
        } catch (a) {
            console.log('Notify library is missing, please make sure you have the notifications library added.')
        }
    }
};
var big_image;
nowuiDashboardDemo = {
    checkScrollForParallax: debounce(function() {
        $(this).scrollTop();
        oVal = $(window).scrollTop() / 3, big_image.css({
            transform: 'translate3d(0,' + oVal + 'px,0)',
            "-webkit-transform": 'translate3d(0,' + oVal + 'px,0)',
            "-ms-transform": 'translate3d(0,' + oVal + 'px,0)',
            "-o-transform": 'translate3d(0,' + oVal + 'px,0)'
        })
    }, 6)
};

function debounce(a, b, c) {
    var d;
    return function() {
        var e = this,
            f = arguments;
        clearTimeout(d), d = setTimeout(function() {
            d = null, c || a.apply(e, f)
        }, b), c && !d && a.apply(e, f)
    }
}

function hexToRGB(a, c) {
    var d = parseInt(a.slice(1, 3), 16),
        e = parseInt(a.slice(3, 5), 16),
        f = parseInt(a.slice(5, 7), 16);
    return c ? 'rgba(' + d + ', ' + e + ', ' + f + ', ' + c + ')' : 'rgb(' + d + ', ' + e + ', ' + f + ')'
}